<template>
  <div id="container"></div>
</template>
<script>
import AMapLoader from "@amap/amap-jsapi-loader";

export default {
  name: "map-view",
  props: {
    mapData: {
      //中心点
      type: Object,
      required: false,
      default: () => {
      },
    },
    deviceInfoList: {
      type: Array,
      required: false,
      default: () => [],
    },
    type: {
      type: String,
      default: "",
    },
    height: {
      type: String,
      default: "calc(100vh - 50px)",
    },
    path: {
      type: Array,
      default: () => [],
    },
    name: {
      type: Array,
      default: () => [],
    },
    info: {
      type: Object,
      default: () => {
      },
    },
  },
  data() {
    return {
      geojson: null,
    };
  },
  mounted() {
    this.initAMap();
  },
  unmounted() {
    this.map?.destroy();
  },
  methods: {
    initAMap() {
      window._AMapSecurityConfig = {
        securityJsCode: "6d55f7be376408a84f88c266ce17c7ee",
      };
      AMapLoader.reset();
      AMapLoader.load({
        key: "462c641e637730356419311563662320",
        version: "2.0",
        plugins: [
          "AMap.Scale",
          "AMap.MouseTool",
          "AMap.Marker",
          "AMap.ContextMenu",
          "AMap.Polygon",
          "AMap.GeoJSON",
        ],
      })
          .then((AMap) => {
            // 创建图片图层
            var disCountry = new AMap.DistrictLayer.Country({
              zIndex: 10,
              SOC: 'CHN',
              depth: 1,
              styles: {
                'nation-stroke': '#061b84',
                'coastline-stroke': 'rgb(6,27,132)',
                'province-stroke': 'rgb(6,27,132)',
                'fill': "rgb(48,144,255)"
              }
            })


// 将图层添加至地图实例
            this.map = new AMap.Map("container", {
              resizeEnable: true,
              // 设置地图容器id
              viewMode: "3D", // 是否为3D地图模式
              //mapStyle: "amap://styles/macaron",

              zoom: this.mapData.zoom || 11, // 初始化地图级别
              layers: [ // 卫星
                //new AMap.TileLayer.Satellite(),
                disCountry
                // 路网
                //new AMap.TileLayer.RoadNet(),
                //图片图层
                //new AMap.TileLayer(),
                //imageLayer
              ],
              center: this.mapData.centerPosition.split(","), // 初始化地图中心点位置 106.865155,29.448244

            });
            this.drawJson();
          })
          .catch((e) => {
            //console.log(e);
          });
    },
    async drawJson() {
      // 设置地图zoom值
      if (this.mapData.zoom) this.map.setZoom(this.mapData.zoom)
      if (this.mapData.polygonWkt) {
        let polygon = new AMap.Polygon({
          path: JSON.parse(this.mapData.polygonWkt), // 设置多边形坐标数组
          strokeColor: "#4695f3", // 线条颜色
          strokeWeight: 4, //线条宽度
          fillColor: "#000", // 填充颜色
          fillOpacity: 0.2, // 填充透明度
        });
        polygon.setMap(this.map);

      }
      // 绘制标记

      console.log("绘制标记：", this.deviceInfoList)
      const makerList = []
      this.deviceInfoList.forEach(v => {
        const lat1 = v.centerPos && v.centerPos !== "[]" ? v.centerPos.replace("[", "").replace("]", "").split(',') : '';
        console.log(lat1)

        if (lat1 && lat1.length > 0) {
          const marker = new AMap.Marker({
            text: "name",
            position: new AMap.LngLat(lat1[0], lat1[1]),
            offset: new AMap.Pixel(-10, -10),
            icon: require("@/assets/cloud2/index/point.png"),
            zooms: [5, 18],
          });
          marker.msg = v;
          marker.setLabel({
            content: v.baseName,
            direction: top,
          })
          marker.on("click", (e) => {
            this.getInfo(e);
            //this.$emit('onMarkerChange', v.id)//大屏数据联动
          });
          makerList.push(marker)
        }

      })
      console.log("点点点点点", makerList)
      this.map.add(makerList);


      //绘制geojson
      if (this.mapData.geoJson) {
        await fetch(`/geojson/${this.mapData.geoJson}.json`)
            .then((response) => response.json())
            .then(async (data) => {
              // console.log("最终geojson数据：",data)


              let dataGeo = [];
              await new AMap.GeoJSON({
                geoJSON: data,
                getPolygon: function (geojson, lnglats) {
                  dataGeo = lnglats[0];
                },
              });
              var polygon = new AMap.Polygon({
                path: dataGeo, // 设置多边形坐标数组
                strokeColor: "yellow", // 线条颜色
                strokeWeight: 4, //线条宽度
                fillColor: "red", // 填充颜色
                fillOpacity: 0.1, // 填充透明度
              });
              polygon.setMap(this.map);

            })
            .catch((error) => {
              console.error("Error loading data:", error);
            });
        // 创建纯文本标记
        /*const lat = this.mapData.centerPosition.split(",");
        var text = new AMap.Text({
          text:this.mapData.mapName,
          anchor:'center', // 设置文本标记锚点
          draggable:false,
          cursor:'pointer',
          angle:0,
          style:{
            'padding': '.75rem 1.25rem',
            'margin-bottom': '1rem',
            'border-radius': '.25rem',
            'background-color': 'rgba(0,0,0,0)',
            'width': '15rem',
            'border-width': 0,
            'text-align': 'center',
            'font-size': '14px',
            'color': 'yellow',
            'font-weight':'bold',
          },
          position: new AMap.LngLat(lat[0], lat[1]),
        });

        text.setMap(this.map);*/

      }
    },

    getInfo(e) {
      // 创建 infoWindow 实例
      let infoWindow = new AMap.InfoWindow({
        offset: new AMap.Pixel(0, -20),
        content: `
          <div class="infoBox">
            <div class="info_title">${e.target.msg.baseName || ""}</div>
            <div class="info_item">
            负责人： ${e.target.msg.basePrincipal || ""}
              </div>
              <div class="info_item">
              栽植面积：<span>${e.target.msg.baseArea || ""}亩</span>
              </div>
              <div class="info_item">
              产量：<span>${e.target.msg.cont1 || ""}吨</span>
              </div>
              <div class="info_item">
              地址：<span>${e.target.msg.baseAddress || ""}</span>
              </div>
              </div>
            `, //传入 dom 对象，或者 html 字符串
      });
      // 打开信息窗体
      infoWindow.open(this.map);
      infoWindow.open(this.map, [e.lnglat.lng, e.lnglat.lat]);
      // 关闭信息窗体
      // infoWindow.close();
      // this.$emit('markerClick',e.target?.deviceNumber||this.markList[0].icon.deviceNumber)
    },
  },
};
</script>
<style lang="less">
#container {
  padding: 0px;
  margin: 0px;
  width: 100%;
  height: 100%;
  background-image: none !important;
  background-color: transparent !important;
}

.amap-marker-label {
  color: #ffffff;
  border: 1px solid #fff;
  font-weight: bold;
  background-color: rgb(2, 156, 252);


}

.initMaped {
  width: 100%;
  height: 100% !important;
  position: absolute;
}

.initMaped .amap-icon img {
  width: 2vw;
}

#screenMap ::v-deep .amap-layer polyline {
  filter: drop-shadow(0 0 20px rgba(255, 0, 0, 0.8));
}

.meng_flow {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: url("~@/assets/smartStorgeScreen/bgc_main.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  z-index: 2;
}

.initMaped .screenMap {
  position: relative;
  top: 0;
  width: 100%;
  height: 100% !important;
}

.bottom-center .amap-info-sharp {
  display: none !important;
}

.amap-info-close {
  color: #55e2eb !important;
}

.amap-info-content {
  padding: 0 !important;
  box-sizing: border-box;
  background: transparent !important;
  display: flex;
  position: relative;

  .titleLeft {
    position: absolute;
    top: 1.58vh;
    left: 0.94vw;
    font-size: 1.48vh;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
  }

  .titleRight {
    left: auto !important;
    top: 0.8vh;
    right: 0.94vw;
    font-size: 1.11vh;
  }

  // 窗体样式
  .imgBox {
    width: 5.94vw;
    height: 10.56vh;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .infoBox {
    width: 100%;
    height: 7vw;
    display: flex;
    // flex-direction: column;
    // justify-content: space-between;
    flex-wrap: wrap;
    background-image: url("~@/assets/cloud2/smartStorgeScreen/infowindow_bgc.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: 0.5vw 1vw;
    box-sizing: border-box;

    .info_title {
      font-size: 0.9vw;
      width: 100%;
      font-weight: bold;
      margin-bottom: 1vh;
      color: #55e2eb;
    }

    .info_item {
      font-size: 0.7vw;
      width: 100%;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #dbf3ff;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-right: 0.5vw;
      // .colorOn {
      //   color: #80ee03 !important;
      // }
    }
  }
}
</style>
